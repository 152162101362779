// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$frontend-primary: mat.define-palette(mat.$green-palette);
$frontend-accent: mat.define-palette(mat.$grey-palette);

// The warn palette is optional (defaults to red).
$frontend-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$frontend-theme: mat.define-light-theme((color: (primary: $frontend-primary,
        accent: $frontend-accent,
        warn: $frontend-warn,
      ),
    ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($frontend-theme);

html,
body {
  //height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  color: rgb(255, 255, 255);
}

.mat-flat-button,
.mat-raised-button,
.mat-fab,
.mat-mini-fab {
  text-transform: uppercase;
}

.pb-1 {
  padding-bottom: 0.5rem;
}

.pb-2 {
  padding-bottom: 1rem;
}

.pb-3 {
  padding-bottom: 1.5rem;
}

.pb-4 {
  padding-bottom: 2rem;
}

.pr-1 {
  padding-right: 0.5rem;
}

.pr-2 {
  padding-right: 1rem;
}

.pr-3 {
  padding-right: 1.5rem;
}

.pr-4 {
  padding-right: 2rem;
}

.mt-1 {
  margin-top: 0.5rem;
}

.mt-2 {
  margin-top: 1rem;
}

.mt-3 {
  margin-top: 1.5rem;
}

// sn table column
.mat-column-sn {
  max-width: 50px;
}

.card-heading {
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.55);
  font-weight: 500;
}

.form-separator-hr {
  border: none;
  margin: 0;
  border-bottom: 1px solid gainsboro;
  margin-bottom: 20px;
}